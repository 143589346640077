<template>
  <main-layout page-title="Your Account">
    <div class="ion-padding">
      <h1>Welcome {{ name }}</h1>

      <ion-button color="danger" @click="logout">Logout</ion-button>

      <hr />

      <news />
    </div>
  </main-layout>
</template>

<script>
import session from "../modules/session";
import { IonButton } from "@ionic/vue";
import News from "@/components/News.vue";

export default {
  data: () => ({
    name: session.userData.value.name,
  }),
  components: { IonButton, News },
  methods: {
    logout() {
      session.logout();
      this.$auth.logout();
    },
  },
};
</script>
