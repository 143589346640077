<template>
  <div class="notification-container">
    <div class="py-2 px-1" style="background: none">
      <strong>EXPERT TIPS and NEWS</strong>
    </div>
    <div>
      <div class="notification card">
        <div v-for="announcement of announcements" :key="announcement.id">
          <div class="card-header d-flex">
            <!-- Notification Icon -->
            <figure>
              <img
                :src="announcement.creator.photo_url"
                class="spark-profile-photo"
              />
            </figure>
            <div class="meta ml-2 align-self-center">
              {{ announcement.creator.name }}
              <small class="date text-muted d-block">
                {{ announcement.created_at }}
              </small>
            </div>
          </div>

          <!-- Announcement -->
          <div class="card-body notification-content py-0">
            <div class="notification-body">
              <div
                :class="announcement.shortened ? 'blurry-text' : ''"
                v-html="announcement.parsed_body"
              ></div>

              <div v-if="announcement.shortened" class="text-center">
                <div>Want to see more news?</div>
                <div class="mb-2 small">
                  Become a Teepster member for many more benefits
                </div>
                <a href="/todo" class="btn btn-link btn-sm">Learn More</a>
              </div>
            </div>
          </div>

          <!-- Announcement Action -->
          <div class="card-footer text-right" v-if="announcement.action_text">
            <a href="{{ announcement.action_url }}" class="btn btn-link">
              {{ announcement.action_text }}
            </a>
          </div>

          <hr />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({ announcements: [] }),
  mounted() {
    axios.get("/announcements").then((response) => {
      this.announcements = response.data.announcements;
    });
  },
};
</script>
